import { PFP_DATA } from 'constants/pfpElements';

export const getItemRarity = (attributeName: string) => {
  const foundItem = PFP_DATA.flatMap(
    categoryData => categoryData.elements
  ).find(item => {
    if (item.colors) {
      return item.colors.some(color => color.filename.includes(attributeName));
    }
    return item.filename === attributeName;
  });

  if (foundItem) {
    const colorRarity =
      foundItem.colors?.find(color => color.filename.includes(attributeName))
        ?.rarity ?? 'Common';
    return {
      name: foundItem.name,
      rarity: foundItem.rarity,
      colorRarity,
    };
  } else {
    if (attributeName === 'None') {
      return {
        name: 'None',
        rarity: '',
      };
    }
  }

  return null; // Item not found in PFP_DATA
};
