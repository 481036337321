import { FC, useEffect, useState } from 'react';

import { PFP_ITEM } from 'types/pfp';
import { getItemRarity } from 'utils/get-item-rarity';

import Hotspot from './Hotspot/Hotspot';

export interface HotspotsProps {
  pfp?: PFP_ITEM;
}

const Hotspots: FC<HotspotsProps> = ({ pfp }) => {
  const [isHotspotActive, setIsHotspotActive] = useState({
    capsule: false,
    head: false,
    headAccessory: false,
    armAccessory: false,
    backAccessory: false,
    hands: false,
    leftHandAccessory: false,
    rightHandAccessory: false,
    torso: false,
    legs: false,
    feet: false,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    function setHotspotData(obj) {
      const newData = {};

      for (const key in obj) {
        if (isHotspotActive[key] !== undefined) {
          const item = getItemRarity(obj[key].filename);
          const name = item?.name || 'Original';
          const rarity = item?.rarity || 'Common';
          const colorRarity = item?.colorRarity || 'Common';

          newData[key] = {
            key,
            name,
            rarity,
            colorRarity,
          };
        }
      }
      setData(newData);
    }
    setHotspotData(pfp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data &&
        Object.keys(data).map(key => {
          if (data[key].name !== 'None') {
            return (
              <Hotspot
                key={key}
                data={data[key]}
                setIsHotspotActive={setIsHotspotActive}
                isHotspotActive={isHotspotActive}
              />
            );
          }
        })}
    </>
  );
};

export default Hotspots;
