import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Label = styled.div`
  p {
    text-align: left;
    white-space: nowrap;
    font-size: 35rem;
    line-height: 40rem;
  }

  p:first-of-type {
    ${({ theme: { locale } }) => setTypography('heading5Hotspot', locale)};
    margin-bottom: 5rem;
  }
`;

export const Tooltip = styled.section<{ $visible: boolean }>`
  position: absolute;
  top: -220rem;
  left: -50rem;
  background: ${({ theme }) => theme.colors.rumSwizzle};
  color: ${({ theme }) => theme.colors.black};
  filter: drop-shadow(0px -4rem 10rem ${({ theme }) => theme.colors.cyanAqua});
  min-height: 200rem;
  border-radius: 35rem;
  border: 10rem solid ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30rem 30rem 20rem;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in;
  z-index: 10;

  ${({ $visible }) =>
    $visible === true &&
    css`
      opacity: 1;
    `};
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 10rem;

  > .hotspot {
    position: relative;
    border: 3px solid black;
    background: ${colors.turbo};
    height: 33rem;
    width: 33rem;
    border-radius: 50%;
    transition: all 0.2s ease-in;
  }

  > .hotspot:hover {
    border: 2px solid black;
    transform: scale(1.3);
    background: ${colors.white};

    .decor {
      opacity: 1;
    }
  }

  .decor {
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    height: 45%;
    width: 45%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.black};
    z-index: 5;
    opacity: 0;
    pointer-events: none;
  }

  &.backAccessory {
    top: 36%;
    left: 64%;
  }

  &.head {
    top: 35%;
    left: 51%;
  }

  &.headAccessory {
    top: 24%;
    left: 48%;
  }

  &.capsule {
    top: 56%;
    left: 19%;
  }

  &.armAccessory {
    top: 43%;
    left: 65%;
  }

  &.rightHandAccessory {
    top: 31%;
    left: 12%;
  }

  &.leftHandAccessory {
    top: 59%;
    left: 66%;
  }

  &.hands {
    top: 41%;
    left: 13%;
  }

  &.torso {
    top: 40.5%;
    left: 36%;
  }

  &.legs {
    top: 54%;
    left: 55%;
  }

  &.feet {
    top: 79%;
    left: 59%;
  }
`;
