import { PFP_ATR, PFP_ITEM } from 'types/pfp';

export const pfpBase = '/images/nft/parts/all/pfp_layer_base.png';
export const capsuleOutline = '/images/nft/parts/all/capsule_outline.png';
export const capsuleGradient = '/images/nft/parts/all/capsule_gradient.png';
export const base6 = '/images/nft/parts/all/pfp_layer_base_m6.png';
export const base9 = '/images/nft/parts/all/pfp_layer_base_m9.png';

import { assetsPath } from 'config/config';
import { PFP_ATRIBIUTES, Rarity } from 'constants/enum';
import { PFP_DATA } from 'constants/pfpElements';

export const buildS0Atribbiutes = url => {
  return {
    description: 'SJ description',
    externalUrl: 'https://www.slimjim.com/',
    image: url,
    name: 'SJ NFT',
    pfpId: '0',
    attributes: {
      headAccessory: ' ',
      leftHandAccessory: ' ',
      hands: ' ',
      rightHandAccessory: ' ',
      armAccessory: ' ',
      torso: ' ',
      feet: ' ',
      legs: ' ',
      head: ' ',
      backAccessory: ' ',
      capsule: ' ',
    },
  };
};

export const buildTestNFT = (url, atribbiutes) => {
  const data = JSON.parse(atribbiutes);

  return {
    description: 'description',
    externalUrl: 'https://www.slimjim.com/',
    image: url !== '' ? url : data.url,
    name: 'SJ NFT',
    attributes: {
      headAccessory: 'string',
      leftHandAccessory: 'string',
      hands: 'string',
      rightHandAccessory: 'string',
      armAccessory: 'string',
      torso: 'string',
      feet: 'string',
      legs: 'string',
      head: 'string',
      backAccessory: 'string',
      capsule: 'string',
    },
  };
};

export const buildTestS0NFT = (url, atribbiutes) => {
  const data = JSON.parse(atribbiutes);

  return {
    description: 'description',
    externalUrl: 'https://www.slimjim.com/',
    image: url !== '' ? url : data.url,
    name: 'SJ NFT',
    attributes: {
      headAccessory: 'string',
      leftHandAccessory: 'string',
      hands: 'string',
      rightHandAccessory: 'string',
      armAccessory: 'string',
      torso: 'string',
      feet: 'string',
      legs: 'string',
      head: 'string',
      backAccessory: 'string',
      capsule: 'string',
    },
  };
};

export const getElementsWhereCategory = category =>
  PFP_DATA.find(element => element.category === category.descName);

const getSecondArmWhereFilename = filename => {
  const [colorRarity, name] = filename
    .split('_')
    .slice(-2)
    .map(part => part.replace(/\.[^.]+$/, ''));
  const element = PFP_DATA.find(
    data => data.category === 'Arm Accessory'
  )?.elements.find(e => e.filename === name);
  return (
    element?.colors.find(
      color => color.rarity.toLowerCase() === colorRarity.toLowerCase()
    )?.['connected'] || null
  );
};

export const getRandomPfpWhereCategory = category => {
  const categoryData = getElementsWhereCategory(category);

  return categoryData.elements[
    Math.floor(Math.random() * (categoryData.elements.length / 2))
  ];
};

export const generateAtrributesArray = (pfp: PFP_ITEM) => {
  const attrs = Object.keys(pfp)
    .filter(property => PFP_ATRIBIUTES[property])
    .map(property => ({
      filename: getUrl(pfp[property].filename),
      marketShare: pfp[property].marketShare,
      name: pfp[property].name,
      category: property,
      layerNo: PFP_ATRIBIUTES[property].layerNo,
    }));

  attrs.push({
    name: 'baseHands',
    filename: base6,
    category: '',
    marketShare: '',
    layerNo: 6.5,
  });

  const secodArm = getSecondArmWhereFilename(pfp['armAccessory'].filename);
  if (secodArm) {
    attrs.push({
      name: 'rightArmAccessory',
      filename: getUrl(secodArm),
      category: '',
      marketShare: '',
      layerNo: 9,
    });
  }

  attrs.push({
    name: 'topHands',
    filename: base9,
    category: '',
    marketShare: '',
    layerNo: 9.5,
  });

  attrs.push({
    name: 'capsuleGradient',
    filename: capsuleGradient,
    category: '',
    marketShare: '',
    layerNo: 15,
  });
  attrs.push({
    name: 'capsuleOutline',
    filename: capsuleOutline,
    category: '',
    marketShare: '',
    layerNo: 0,
  });

  attrs.sort((a, b) => {
    return b.layerNo - a.layerNo;
  });

  return attrs;
};

export const getRandomElementWhereCategory = category =>
  getRandomPfpWhereCategory(category);

export const getRandomRarity = (): Rarity =>
  Object.values(Rarity)[
    Math.floor(Math.random() * (Object.keys(Rarity).length / 2))
  ];

export const buidRandomPpfATRWhereCategory = (category): PFP_ATR => {
  const element = getRandomElementWhereCategory(category);
  const pfpElementData = getRandomPfpFilename(element);
  const color = pfpElementData.rarity !== '' ? `${pfpElementData.rarity} ` : '';

  return {
    filename: pfpElementData.filename,
    name: `${color}${element.name}`,
    marketShare: Math.floor(Math.random() * 100),
  };
};

export const getRandomPfpFilename = data => {
  let filename = '';
  let rarity = null;
  if (data.colors?.length) {
    const element = data.colors[Math.floor(Math.random() * data.colors.length)];
    filename = element.filename;
    rarity = element.rarity;
  } else if (data.name !== 'None') {
    filename = data.filename;
  }

  return { filename, rarity };
};
export const getUrl = filename =>
  filename === '' || filename === 'None' ? '' : `${assetsPath()}${filename}`;
