import { FC } from 'react';

import * as Styled from './Hotspot.styles';

export interface HotspotProps {
  data: any;
  setIsHotspotActive: (Object) => void;
  isHotspotActive: Record<string, boolean>;
}

const Hotspot: FC<HotspotProps> = ({
  data,
  setIsHotspotActive,
  isHotspotActive,
}) => {
  const handleHover = show => {
    setIsHotspotActive({
      ...isHotspotActive,
      [data.key]: show,
    });
  };

  return (
    <>
      <Styled.TooltipWrapper className={data.key}>
        <Styled.Tooltip $visible={isHotspotActive[data.key]}>
          <Styled.Label>
            <p>{data.name}</p>
            <p>{`RARITY: ${data.rarity}`}</p>
            <p>{`COLOR: ${data.colorRarity}`}</p>
          </Styled.Label>
        </Styled.Tooltip>
        <div
          className="hotspot"
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <span className="decor" />
        </div>
      </Styled.TooltipWrapper>
    </>
  );
};

export default Hotspot;
