import { FC, useEffect, useRef, useState } from 'react';

import Hotspots from 'components/Hotspots/Hotspots';
import { generateGalleryMock } from 'services/api/mockData';
import { generateAtrributesArray } from 'services/api/utils';
import { useGlobalStore } from 'store';
import { PFP_ITEM } from 'types/pfp';
import assets from 'utils/assets';

import * as Styled from './Capsule.styles';

export interface CapsuleProps {
  pfp?: PFP_ITEM;
  empty?: boolean;
  random?: boolean;
  withLoader?: boolean;
  showHotspots?: boolean;
  showEmptyCapsuleBeforeLoading?: boolean;
}

const Capsule: FC<CapsuleProps> = ({
  pfp,
  random,
  empty = false,
  withLoader = true,
  showHotspots = false,
  showEmptyCapsuleBeforeLoading = false,
}) => {
  const { isDesktopView } = useGlobalStore();
  const [attributes, setAttributes] = useState([]);
  const [loadedCounter, setLoadedCounter] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoadedCounter(0);
    setAttributes(
      generateAtrributesArray(
        pfp === undefined || pfp === null || random
          ? generateGalleryMock(1).pfps[0]
          : pfp
      )
    );
  }, [pfp, random]);

  // check if all images are loaded
  useEffect(() => {
    if (loadedCounter === attributes.length) {
      showCapsule();
    }
  }, [attributes, loadedCounter]);

  const showCapsule = () => {
    timeoutRef.current = setTimeout(() => setLoaded(true), 2500);
  };
  const getCapsuleOutline = () =>
    attributes.find(attribute => attribute.name === 'capsuleOutline') || null;

  useEffect(() => {
    return () => {
      timeoutRef?.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Styled.Wrapper>
      {!empty ? (
        <Styled.CapsuleWrapper>
          {attributes.length > 0 && showEmptyCapsuleBeforeLoading && (
            <>
              <Styled.Img
                alt={attributes[0].name}
                $shown
                $empty
                src={attributes[0].filename}
                $mixBlendMode={
                  attributes[0].name === 'capsuleGradient' ? 'hard-light' : ''
                }
              />
              <Styled.Img
                alt={attributes[1].name}
                $shown
                $empty
                src={attributes[1].filename}
                $mixBlendMode={
                  attributes[1].name === 'capsuleGradient' ? 'hard-light' : ''
                }
              />
            </>
          )}
          {attributes
            .filter((attr, i) => {
              if (showEmptyCapsuleBeforeLoading) {
                if (i < 1 || attr.name === 'capsuleOutline') return false;
              }
              return true;
            })
            .map(
              (attrData: any, index: number) =>
                attrData.filename !== '' && (
                  <Styled.Img
                    key={index}
                    alt={attrData.name}
                    onLoad={() => setLoadedCounter(count => ++count)}
                    src={attrData.filename}
                    $shown={loaded}
                    $mixBlendMode={
                      attrData.name === 'capsuleGradient' ? 'hard-light' : ''
                    }
                  />
                )
            )}
          {attributes.length > 0 && showEmptyCapsuleBeforeLoading && (
            <Styled.Img
              alt={getCapsuleOutline()?.name}
              $shown
              $empty
              src={getCapsuleOutline()?.filename}
              $mixBlendMode={
                getCapsuleOutline()?.name === 'capsuleGradient'
                  ? 'hard-light'
                  : ''
              }
            />
          )}
          {showHotspots && isDesktopView && loaded && <Hotspots pfp={pfp} />}
        </Styled.CapsuleWrapper>
      ) : (
        <Styled.CapsuleWrapper>
          {attributes.length > 0 && (
            <>
              <Styled.Img
                alt={attributes[0].name}
                $shown={true}
                $empty={true}
                src={attributes[0].filename}
                $mixBlendMode={
                  attributes[0].name === 'capsuleGradient' ? 'hard-light' : ''
                }
              />
              <Styled.Img
                alt={attributes[1].name}
                $shown={true}
                $empty={true}
                src={attributes[1].filename}
                $mixBlendMode={
                  attributes[1].name === 'capsuleGradient' ? 'hard-light' : ''
                }
              />
              <Styled.Img
                alt={getCapsuleOutline()?.name}
                $shown={true}
                $empty={true}
                src={getCapsuleOutline()?.filename}
                $mixBlendMode={
                  getCapsuleOutline()?.name === 'capsuleGradient'
                    ? 'hard-light'
                    : ''
                }
              />
            </>
          )}
        </Styled.CapsuleWrapper>
      )}
      <Styled.Loader $shown={withLoader && !loaded}>
        <Styled.image
          src={assets.gifs.capsule.loader}
          width={660}
          height={1280}
        />
      </Styled.Loader>
    </Styled.Wrapper>
  );
};

export default Capsule;
