import { GALLERY_DATA } from 'types/gallery';

import { buidRandomPpfATRWhereCategory, getRandomRarity } from './utils';

import { PFP_ATRIBIUTES } from 'constants/enum';

export const generateGalleryMock = (amount): GALLERY_DATA => {
  const galleryMock = [];

  for (const index of Array.from({ length: amount }, (_, index) => index)) {
    galleryMock.push({
      id: index,
      url: `/images/gallery/${(index % 2) + 1}.png`,
      nftName: `Gigajim #${index}`,
      rarity: getRandomRarity().toUpperCase(),
      dateOfBirth: '2023-03-26T15:33:41.954Z',
      score: index,
      type: 'SEASON1',
      feet: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.feet),
      capsule: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.capsule),
      headAccessory: buidRandomPpfATRWhereCategory(
        PFP_ATRIBIUTES.headAccessory
      ),
      leftHandAccessory: buidRandomPpfATRWhereCategory(
        PFP_ATRIBIUTES.leftHandAccessory
      ),
      hands: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.hands),
      armAccessory: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.armAccessory),
      rightHandAccessory: buidRandomPpfATRWhereCategory(
        PFP_ATRIBIUTES.rightHandAccessory
      ),
      torso: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.torso),
      legs: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.legs),
      head: buidRandomPpfATRWhereCategory(PFP_ATRIBIUTES.head),
      backAccessory: buidRandomPpfATRWhereCategory(
        PFP_ATRIBIUTES.backAccessory
      ),
    });
  }

  return { all: 100, pfps: galleryMock, nextPage: null, previousPage: null };
};
