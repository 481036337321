import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 1285 / 2492;
  width: 100%;
  height: auto;
`;

export const CapsuleWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Img = styled.img<{
  $mixBlendMode?: string;
  $shown?: boolean;
  $empty?: boolean;
}>`
  position: absolute;
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${({ $empty }) => ($empty ? '1' : '0')};
  transition: all 1s ease-in;

  ${({ $shown, $mixBlendMode = 'normal' }) =>
    $shown
      ? css`
          opacity: 1;
          visibility: visible;
          mix-blend-mode: ${$mixBlendMode};
        `
      : css`
          opacity: 0;
          visibility: hidden;
          mix-blend-mode: hard-light;
        `}
`;

export const Loader = styled.div<{ $shown: boolean }>`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${({ $shown }) => ($shown ? 'flex' : 'none')};
  mix-blend-mode: hard-light;
`;

export const image = styled.img`
  z-index: 1;
  position: absolute;
  width: 132%;
  height: auto;
  top: 48%;
  left: 49%;
  transform: translate(-50%, -50%);
`;
